.App {
  text-align: center;
  width: 100%;
}

.pokemonCardContainer{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  gap: 20px;
  margin: 20px 10px 0 10px;
}

.btn{
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.btn button{
  padding: 13px 32px;
  background-color:white;
  border: none;
  box-shadow: 14px 21px 27px 6px #777777;
  border-radius: 5px;
  color:black;
  cursor: pointer;
  transition: all 0.3s;
}

.btn button:hover{
  transform: translateY(5px);
  box-shadow: none;
}

.btn button :disabled{
  transform: none;
  box-shadow: none;
  color: #777777;
}