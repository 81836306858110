/* .rates{
    width: 100%;
    height: auto;
    background-color: aliceblue;
    display: flex;
} */


.rate{
    text-align: center;
    margin-top: 30px;
    font-size: 23px;
    font-weight: 800;
}