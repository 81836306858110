*:focus{
    outline: none;
}

.exchange {
    text-align: center;
    justify-content: center;
    position: relative;
}
.inputValue {
    margin-top: 20px;
    width: 150px;
    height: 40px;
}
.exchange_button {
    cursor: pointer;
    background-color: white;
    padding:10px 2px;
    margin-left: 10px;
    border: solid black 1px;
}

input{
    text-align: center;
    font-size: large;
    border: none;

}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} 

input[type="number"] { 
    -moz-appearance:textfield; 
}

.exchangedValue {
    padding-top: 10px;
    width: 220px;
    height: 40px;
    margin: 0 auto;
    background-color: white;
}

