* {
    box-sizing: border-box;
  }

.topMenu {
  min-height: 100%;
  text-align: center;
  overflow: hidden;
}

.strip {
    will-change: width, left, z-index, height;
  position: absolute;
  width: 100%;
  min-height: 50vh;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.strip:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
}
.strip:nth-child(2) {
    top: 50vh;
    left: 0;
    width: 100%;
}

.strip:nth-child(1) .stripContent {
    background-image:url(../../../public/hawaii.JPG);
    background-size: cover;
    transform: translate3d(-100%, 0, 0);
    -webkit-animation-name: strip1;
            animation-name: strip1;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
}

.strip:nth-child(2) .stripContent {
    background-image:url(../../../public/korea.jpg) ;
    background-size: cover;
    transform: translate3d(0, 100%, 0);
    -webkit-animation-name: strip2;
            animation-name: strip2;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
}

@media screen and (max-width: 760px) {
    .strip {
      min-height: 50vh;
    }
    .strip:nth-child(1) {
      top: 0;
      left: 0;
      width: 100%;
    }
    .strip:nth-child(2) {
      top: 50vh;
      left: 0;
      width: 100%;
    }
}

.topMenu .stripContent {
    -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.topMenu .stripContent:hover:before {
    transform: skew(-30deg) scale(3) translate(0, 0);
    opacity: 0.1;
}

.topMenu .stripContent:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: black;
    opacity: 0.05;
    transform-origin: center center;
}

.topMenu .stripInnerText {
  background-color: bisque;
    will-change: transform, opacity;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    padding-bottom: 40px;
}

.strip--expanded {
  background: white;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    z-index: 3;
    cursor: default;
}

@media screen and (max-width: 760px) {
    .strip--expanded {
      min-height: 100vh;
    }
}

/* .strip--expanded .stripContent:hover:before {
    transform: skew(-30deg) scale(1) translate(0, 0);
    opacity: 0.05;
} */

.strip--expanded h1 {
    opacity: 0;
}

.strip--expanded .stripInnerText {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

h1 {
    display: block;
    margin: 0;
    position: relative;
    z-index: 2;
    width: 100%;
    font-size: 7vw;
    color: white;
    /* transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1); */
}

@media screen and (max-width: 760px) {
    h1 {
      font-size: 10vw;
    }
}

.stripClose {
    position: absolute;
    right: 3vw;
    top: 3vw;
    opacity: 0;
    z-index: 10;
    transition-property: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    cursor: pointer;
    /* transition-delay: 0.5s; */
}
.strip__close--show {
  width: 20px;
  height: 20px;
  opacity: 1;
  font-size: 30px;
  color:white;
}

@-webkit-keyframes strip1 {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
}
  
  @keyframes strip1 {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes strip2 {
    0% {
      transform: translate3d(0, 100%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
}
  @keyframes strip2 {
    0% {
      transform: translate3d(0, 100%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
}
  
body {
    font-family: "Lato";
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;
    line-height: 1.5;
}
  
h1, h2 {
    font-weight: 900;
}
  
/* .fa {
    font-size: 30px;
    color: white;
} */
  
h2 {
    padding-top: 40px;
    font-size: 36px;
    margin: 0 0 16px;
    color:black;
    font-weight: 900;
}
  
p {
    margin: 0 0 16px;
}
  