.weather {
    font-size: 23px;
    font-weight: 700;
    text-align: center;
    position: relative;
}

/* .icon {
    width: 100%;
    height: auto;
} */

img {
    width: 35px;
    height: auto;
    align-items: center;
    position: absolute;
    vertical-align: middle;
}